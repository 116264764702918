package com.essntl.features.category.domain.model

data class CategoryModel(
    val id: String,
    val name: String,
    val createdAt: String,
    val iconUrl: String?,
    val serviceDynamicFields: List<String>?,
    val qbCategoryId: String?,
) {
    val isEmpty
        get() = this == empty

    companion object {
        val all =
            CategoryModel(
                id = "All",
                name = "All",
                createdAt = "",
                iconUrl = null,
                serviceDynamicFields = null,
                qbCategoryId = null,
            )

        val empty =
            CategoryModel(
                id = "",
                name = "",
                createdAt = "",
                iconUrl = null,
                serviceDynamicFields = null,
                qbCategoryId = null,
            )
    }
}
