package com.essntl.features.itinerary.data.supabase.itinerary_service

import com.essntl.features.itinerary.domain.model.ItineraryServiceModel
import com.essntl.features.supplier.data.supabase.SupplierDto
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ItineraryServiceDto(
    val id: String,
    @SerialName("service_id")
    val serviceId: String,
    val grouped: Boolean,
    @SerialName("additional_info")
    val additionalInfo: String?,
    @SerialName("edited_description")
    val editedDescription: String?,
    @SerialName("start_date")
    val startDate: String,
    @SerialName("end_date")
    val endDate: String?,
    @SerialName("start_time")
    val startTime: String?,
    @SerialName("end_time")
    val endTime: String?,
    val status: String?,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("updated_at")
    val updatedAt: String?,
    val price: Int?,
    @SerialName("internal_notes")
    val internalNotes: String?,
    @SerialName("supplier_id_data")
    val supplier: SupplierDto?,
    @SerialName("guest_count")
    val guestCount: Int,
    val address: String?,
    @SerialName("updated_by")
    val updatedBy: String?,
    @SerialName("contact_name")
    val contactName: String?,
    @SerialName("contact_phone_number")
    val contactPhoneNumber: String?,
    @SerialName("itinerary_id")
    val itineraryId: String?,
    @SerialName("dynamic_fields")
    val dynamicFields: Map<String, String>?,
    @SerialName("special_notes")
    val specialNotes: String?,
    val paid: Boolean?,
    @SerialName("embedded_map")
    val embeddedMap: String?,
    @SerialName("embedded_content")
    val embeddedContent: String?,
    val files: List<String>?,
    val messages: List<String>?,
    @SerialName("linked_id")
    val linkedId: String? = null,
    @SerialName("po_sent_at")
    val poSentAt: String? = null,
) {
    fun toModel() =
        ItineraryServiceModel(
            id = id,
            serviceId = serviceId,
            grouped = grouped,
            additionalInfo = additionalInfo,
            editedDescription = editedDescription,
            startDate = startDate,
            endDate = endDate,
            startTime = startTime,
            endTime = endTime,
            createdAt = createdAt,
            updatedAt = updatedAt,
            price = price,
            supplier = supplier?.toModel(),
            internalNotes = internalNotes,
            guestCount = guestCount,
            address = address,
            contactName = contactName,
            contactPhoneNumber = contactPhoneNumber,
            updatedBy = updatedBy,
            itineraryId = itineraryId,
            dynamicFields = dynamicFields,
            specialNotes = specialNotes,
            paid = paid,
            embeddedMap = embeddedMap,
            embeddedContent = embeddedContent,
            files = files?.toSet(),
            messages = messages?.toSet(),
            linkedId = linkedId,
            poSentAt = poSentAt,
            status =
            when (status) {
                "READY" -> ItineraryServiceModel.Status.Ready
                "PO_SENT" -> ItineraryServiceModel.Status.POSent
                "CONFIRMED" -> ItineraryServiceModel.Status.Confirmed
                "TO_CHANGE" -> ItineraryServiceModel.Status.TOChange
                "CANCELLED" -> ItineraryServiceModel.Status.Cancelled
                "COMPLETED" -> ItineraryServiceModel.Status.Completed
                else -> null
            },
        )

    companion object {
        fun getColumns() = Columns.raw(COLUMNS_VALUE)

        const val COLUMNS_VALUE = "*, supplier_id_data:supplier_id(*)"
    }
}

internal fun ItineraryServiceModel.Status.toDto() =
    when (this) {
        ItineraryServiceModel.Status.Ready -> "READY"
        ItineraryServiceModel.Status.POSent -> "PO_SENT"
        ItineraryServiceModel.Status.Confirmed -> "CONFIRMED"
        ItineraryServiceModel.Status.TOChange -> "TO_CHANGE"
        ItineraryServiceModel.Status.Cancelled -> "CANCELLED"
        ItineraryServiceModel.Status.Completed -> "COMPLETED"
    }