package com.essntl.features.itinerary.domain.model

import com.essntl.core.utils.datetime.formatTimestampToHHMM
import com.essntl.features.supplier.domain.model.SupplierModel

data class ItineraryServiceModel(
    val id: String,
    val serviceId: String,
    val grouped: Boolean?,
    val additionalInfo: String?,
    val editedDescription: String?,
    val startDate: String,
    val endDate: String?,
    val startTime: String?,
    val endTime: String?,
    val status: Status?,
    val createdAt: String,
    val updatedAt: String?,
    val price: Int?,
    val internalNotes: String?,
    val supplier: SupplierModel?,
    val guestCount: Int,
    val address: String?,
    val updatedBy: String?,
    val contactName: String?,
    val contactPhoneNumber: String?,
    val itineraryId: String?,
    val dynamicFields: Map<String, String>?,
    val specialNotes: String?,
    val paid: Boolean?,
    val embeddedMap: String?,
    val embeddedContent: String?,
    val files: Set<String>?,
    val messages: Set<String>?,
    val linkedId: String?,
    val poSentAt: String?,
) {
    val dynamicFieldsString
        get() =
            dynamicFields
                .orEmpty()
                .entries
                .joinToString("\n") { (key, value) ->
                    val capitalizeKey =
                        key
                            .replace("_", " ")
                            .replaceFirstChar { it.titlecaseChar() }

                    "$capitalizeKey: $value"
                }

    fun getChangedFields(itineraryServiceModel: ItineraryServiceModel): ItineraryServiceModel =
        ItineraryServiceModel(
            id = id,
            createdAt = createdAt,
            updatedAt = updatedAt,
            serviceId = serviceId,
            grouped = if (grouped != itineraryServiceModel.grouped) itineraryServiceModel.grouped else null,
            startDate = if (startDate != itineraryServiceModel.startDate) itineraryServiceModel.startDate else "",
            endDate = if (endDate.orEmpty() != itineraryServiceModel.endDate.orEmpty()) itineraryServiceModel.endDate else null,
            startTime = if (formatTimestampToHHMM(startTime.orEmpty()) !=
                formatTimestampToHHMM(itineraryServiceModel.startTime.orEmpty())
            ) itineraryServiceModel.startTime else null,
            endTime = if (formatTimestampToHHMM(endTime.orEmpty())
                != formatTimestampToHHMM(itineraryServiceModel.endTime.orEmpty())
            ) itineraryServiceModel.endTime else null,
            additionalInfo =
            if (additionalInfo.orEmpty() != itineraryServiceModel.additionalInfo.orEmpty()) {
                itineraryServiceModel.additionalInfo
            } else {
                null
            },
            editedDescription =
            if (editedDescription.orEmpty() != itineraryServiceModel.editedDescription.orEmpty()) {
                itineraryServiceModel.editedDescription
            } else {
                null
            },
            price = if (price != itineraryServiceModel.price) itineraryServiceModel.price else null,
            status = if (status != itineraryServiceModel.status) itineraryServiceModel.status else null,
            internalNotes =
            if (internalNotes.orEmpty() != itineraryServiceModel.internalNotes.orEmpty()) {
                itineraryServiceModel.internalNotes
            } else {
                null
            },
            supplier = if (supplier != itineraryServiceModel.supplier) itineraryServiceModel.supplier else null,
            guestCount = if (guestCount != itineraryServiceModel.guestCount) itineraryServiceModel.guestCount else -1,
            address = if (address.orEmpty() != itineraryServiceModel.address.orEmpty()) itineraryServiceModel.address else null,
            updatedBy = if (updatedBy != itineraryServiceModel.updatedBy) itineraryServiceModel.updatedBy else null,
            contactName = if (contactName.orEmpty() != itineraryServiceModel.contactName.orEmpty()) itineraryServiceModel.contactName else null,
            dynamicFields =
            if (
                dynamicFields == null &&
                itineraryServiceModel.dynamicFields.orEmpty().all { it.value.isEmpty() }
            ) {
                null
            } else if (dynamicFields != itineraryServiceModel.dynamicFields) {
                itineraryServiceModel.dynamicFields
            } else {
                null
            },
            itineraryId = itineraryId,
            specialNotes = if (specialNotes.orEmpty() != itineraryServiceModel.specialNotes.orEmpty()) itineraryServiceModel.specialNotes else null,
            paid = if (paid != itineraryServiceModel.paid) itineraryServiceModel.paid else null,
            contactPhoneNumber =
            if (contactPhoneNumber.takeIf { it != "0" }
                    .orEmpty() != itineraryServiceModel.contactPhoneNumber.takeIf { it != "0" }.orEmpty()) {
                itineraryServiceModel.contactPhoneNumber
            } else {
                null
            },
            embeddedMap = if (embeddedMap.orEmpty() != itineraryServiceModel.embeddedMap.orEmpty()) itineraryServiceModel.embeddedMap else null,
            embeddedContent = if (embeddedContent.orEmpty() != itineraryServiceModel.embeddedContent.orEmpty()) itineraryServiceModel.embeddedContent else null,
            files = if (files != itineraryServiceModel.files) itineraryServiceModel.files else null,
            messages = if (messages != itineraryServiceModel.messages) itineraryServiceModel.messages else null,
            linkedId = if (linkedId != itineraryServiceModel.linkedId) itineraryServiceModel.linkedId else null,
            poSentAt = if (poSentAt != itineraryServiceModel.poSentAt) itineraryServiceModel.poSentAt else null,
        )

    val isEmpty
        get() =
            this.copy(
                id = "",
                serviceId = "",
                createdAt = "",
                updatedAt = null,
                itineraryId = null,
            ) == empty

    enum class Status {
        Ready,
        POSent,
        Confirmed,
        TOChange,
        Cancelled,
        Completed,
    }

    companion object {
        val empty =
            ItineraryServiceModel(
                id = "",
                serviceId = "",
                grouped = null,
                createdAt = "",
                updatedAt = null,
                additionalInfo = null,
                editedDescription = null,
                startDate = "",
                endDate = null,
                startTime = null,
                endTime = null,
                price = null,
                status = null,
                internalNotes = null,
                supplier = null,
                guestCount = -1,
                address = null,
                contactName = null,
                contactPhoneNumber = null,
                updatedBy = null,
                itineraryId = null,
                dynamicFields = null,
                specialNotes = null,
                paid = null,
                embeddedMap = null,
                embeddedContent = null,
                files = null,
                messages = null,
                linkedId = null,
                poSentAt = null,
            )
    }
}